import { combineReducers, configureStore } from "@reduxjs/toolkit"
import AppSlice from "./reducers/app.slice"
import UploadSlice from "./reducers/uploadBuild.slice"

const rootReducer = combineReducers({
	appReducer: AppSlice.reducer,
	uploadBuildReducer: UploadSlice.reducer,
})

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
	})
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
