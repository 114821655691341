import { styled } from "styled-components"

const backColor = "rgba(19,19,20,1)"

export const MainLayoutWrapper = styled.div`
	display: grid;
	grid-template-areas:
		"header header"
		"menu content";
	grid-template-columns: 2fr 8fr;
	grid-template-rows: 80px 1fr;
	gap: 20px;
	height: 100%;
`

export const MainLayoutHeader = styled.div`
	grid-area: header;

	background-color: ${props => props.theme.mainBackgroundColor};
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	place-items: center;

	padding: 0 20px;
`
export const Title = styled.div`
	font-size: 18px;
`
export const AdminInfo = styled.div`
	font-size: 18px;
`
export const MainLayoutMenu = styled.div`
	grid-area: menu;
	position: relative;

	background-color: ${props => props.theme.mainBackgroundColor};
	display: grid;
	padding: 30px 20px;
`
export const MenuContent = styled.div`
	height: min-content;
	display: grid;
	gap: 10px;
`
export const MenuItem = styled.div<{ color: string; colorFont: string }>`
	display: grid;
	place-items: center;
	height: 40px;

	color: ${({ colorFont }) => (colorFont ? colorFont : "black")};

	background-color: ${({ color }) => (color ? color : "rgba(0, 0, 0, 0.1)")};

	&:hover {
		background-color: ${({ color }) => (color ? color : "rgba(0, 0, 0, 0.2)")};
	}

	border-radius: 10px;

	cursor: pointer;
`
export const Logout = styled(MenuItem)`
	position: absolute;
	bottom: 20px;
	right: 0;
	left: 0;
`
export const MainLayoutContent = styled.div`
	grid-area: content;
	background-color: ${props => props.theme.mainBackgroundColor};

	padding: 20px;
`
