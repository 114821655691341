import { Button, Watermark } from "antd"
import { AuthBlock, AuthField, AuthPageContent, AuthPageWrapper, AuthSubmitButton } from "./styles"
import { useAppDispatch, useAppSelector } from "../../state/hooks/redux"
import AppSlice, { loginThunk } from "../../state/reducers/app.slice"
import fast_debug from "../../utils/fast_debug"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const AuthPage = () => {
	const dispatch = useAppDispatch()
	const { auth, isLoadingAuth, isLogined } = useAppSelector(state => state.appReducer)
	const navigate = useNavigate()
	const onChangeEmail = (email: string) => {
		dispatch(AppSlice.actions.setEmail(email))
	}
	const onChangePassword = (pass: string) => {
		dispatch(AppSlice.actions.setPassword(pass))
	}
	const onAuth = () => {
		if (isLoadingAuth) return
		dispatch(loginThunk())
	}

	const handleKeyPress = event => {
		if (event.key === "Enter") {
			onAuth()
		}
	}

	useEffect(() => {
		if (isLogined) navigate("/")
	}, [isLogined])

	return (
		<AuthPageWrapper>
			<AuthPageContent>
				<AuthBlock onKeyDown={handleKeyPress}>
					<AuthField>
						<div>Email</div>
						<input type="text" value={auth.email || ""} onChange={e => onChangeEmail(e?.target?.value)} />
					</AuthField>
					<AuthField>
						<div>Password</div>
						<input type="password" value={auth.password || ""} onChange={e => onChangePassword(e?.target?.value)} />
					</AuthField>
					{/* <AuthSubmitButton onClick={onAuth}>Login</AuthSubmitButton> */}
					<Button type="primary" loading={isLoadingAuth} onClick={onAuth} style={{ width: "100%" }}>
						Login
					</Button>
				</AuthBlock>
			</AuthPageContent>
		</AuthPageWrapper>
	)
}

export default AuthPage
