import { styled } from "styled-components"

export const AuthPageWrapper = styled.div`
	display: grid;
	height: 100%;
`
export const AuthPageContent = styled.div`
	display: grid;
	height: 100%;
	place-items: center;
`
export const AuthBlock = styled.div`
	background-color: white;
	height: min-content;

	padding: 20px;
	border-radius: 10px;
`
export const AuthField = styled.div`
	padding: 0 0 10px;
	div {
		margin-bottom: 5px;
	}
	input {
		border: 1px solid grey;
		border-radius: 5px;
		line-height: 20px;
		font-size: 16px;
		padding: 5px;
	}
`
export const AuthSubmitButton = styled.div`
	margin-top: 20px;
	border-radius: 5px;
	padding: 10px 5px;
	color: white;
	background-color: blue;

	text-align: center;

	cursor: pointer;
`
