import { Route, Routes } from "react-router-dom"
import AuthPage from "../pages/Auth"
import Page404 from "../pages/404"
import WithAuthHOC from "../components/WithAuthHOC"
import MainLayout from "../../src/layouts/MainLayout"
import { UsersPage } from "src/pages/Users"
import { UploadPage } from "src/pages/Upload"
import { VersionsPage } from "src/pages/Versions"

const AppRouter = () => {
	return (
		<Routes>
			<Route path="/auth" element={<AuthPage />} />

			<Route
				path="/"
				element={
					<WithAuthHOC>
						<MainLayout>
							<UsersPage />
						</MainLayout>
					</WithAuthHOC>
				}
			/>
			<Route
				path="/users"
				element={
					<WithAuthHOC>
						<MainLayout>
							<UsersPage />
						</MainLayout>
					</WithAuthHOC>
				}
			/>
			<Route
				path="/upload"
				element={
					<WithAuthHOC>
						<MainLayout>
							<UploadPage />
						</MainLayout>
					</WithAuthHOC>
				}
			/>
			<Route
				path="/versions"
				element={
					<WithAuthHOC>
						<MainLayout>
							<VersionsPage />
						</MainLayout>
					</WithAuthHOC>
				}
			/>

			<Route path="*" element={<Page404 />} />
		</Routes>
	)
}

export default AppRouter
