import { Pagination, Select, Switch, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { useEffect, useState } from "react"
import { ChangeUserGameBuild } from "src/interfaces/requests/changeUserGameBuild"
import { useAppDispatch, useAppSelector } from "src/state/hooks/redux"
import { getUsersListThunk, getVersionsListThunk, changeUserGameVersionThunk } from "src/state/reducers/app.slice"
import { ControlBlock, ControlItem, Field, UserAvatar, UserFakeAvatar, UsersPageWrapper } from "./styles"

interface IDataSource {
	id: number
	key: number
	nickname: string
	avatar: string
	version: string
	isGuest: boolean
}

const filterDataSource = (dataSource: IDataSource[], filterString: string) => {
	return dataSource.filter(item => item?.nickname?.toLocaleLowerCase()?.includes(filterString?.toLocaleLowerCase()))
}

export const UsersPage = () => {
	const dispatch = useAppDispatch()
	const { users, versions } = useAppSelector(state => state.appReducer)
	const [currentPage, setCurrentPage] = useState(1)
	const [filterString, setFilterString] = useState("")
	const [showGuests, setShowGuests] = useState(false)

	useEffect(() => {
		dispatch(getUsersListThunk())
		dispatch(getVersionsListThunk())
	}, [])

	const handleChangeUserBuild = async ({ buildVersion, userId }: ChangeUserGameBuild.Request) => {
		await dispatch(changeUserGameVersionThunk({ buildVersion, userId }))
		await dispatch(getUsersListThunk())
	}

	const handleChangeFilterString = (str: string) => {
		setFilterString(str)
	}

	const handleChangeShowGuests = (mode: boolean) => {
		setShowGuests(mode)
	}

	if (!users?.length) {
		return <>Загрузка</>
	}

	let key = 1
	const getKey = () => key++

	let dataSource: IDataSource[] = users.map((user, index) => ({
		key: index,
		id: user.id,
		email: user?.based_info?.email || "-",
		nickname: user.nickname,
		avatar: user?.telegram_info?.tonplay_avatar,
		version: user?.version?.name,
		isGuest: user.isGuestUser,
	}))

	const columns: ColumnsType<(typeof dataSource)[0]> = [
		{
			title: "ID",
			dataIndex: "id",
			key: getKey(),
			align: "center",
		},
		{
			title: "Nickname",
			dataIndex: "nickname",
			key: getKey(),
			align: "center",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: getKey(),
			align: "center",
		},
		{
			title: "Avatar",
			dataIndex: "avatar",
			key: getKey(),
			render: value => <>{!value ? <UserFakeAvatar>No photo</UserFakeAvatar> : <UserAvatar src={value || ""} alt="" />}</>,
			align: "center",
		},

		{
			title: "Game version",
			dataIndex: "version",
			key: getKey(),
			render: (value, record) => (
				<Select
					value={value}
					onChange={newValue => handleChangeUserBuild({ buildVersion: newValue, userId: record.id })}
					options={
						versions?.length &&
						versions
							.slice()
							.filter(version => version.showInSelect)
							.sort((a, b) => b?.id - a?.id)
							.map(version => ({ value: version.name, title: version.description }))
					}
					loading={false}
					style={{ width: "100px" }}
				/>
			),
			align: "center",
		},
	]

	dataSource = filterDataSource(dataSource, filterString)
	dataSource = showGuests ? dataSource : dataSource.filter(record => !record.isGuest)
	dataSource.sort((a, b) => b.id - a.id)
	return (
		<UsersPageWrapper>
			<ControlBlock>
				<ControlItem>
					<Field>
						<div>Search</div>
						<input type="text" onChange={e => handleChangeFilterString(e?.target?.value)} />
					</Field>
				</ControlItem>
				<ControlItem>
					<Field>
						<div>Show guests</div>
						<Switch checked={showGuests} onChange={handleChangeShowGuests}></Switch>
					</Field>
				</ControlItem>
			</ControlBlock>
			<Table dataSource={dataSource} columns={columns} style={{ width: "auto" }} pagination={false} scroll={{ y: "60vh" }} />
		</UsersPageWrapper>
	)
}
