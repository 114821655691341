import { FC } from "react"
import { AdminInfo, Logout, MainLayoutContent, MainLayoutHeader, MainLayoutMenu, MainLayoutWrapper, MenuContent, MenuItem, Title } from "./styles"
import { useAppSelector } from "../../../src/state/hooks/redux"
import { useNavigate } from "react-router-dom"
import { removePasswordFromSS } from "src/state/reducers/app.slice"

interface MainLayoutProps {
	children: React.ReactNode
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
	const navigate = useNavigate()
	const { adminInfo } = useAppSelector(state => state.appReducer)



	const menuItems = [
		{
			path: "/users",
			title: "Users",
		},
		{
			path: "/versions",
			title: "Versions",
		},
		{
			path: "/upload",
			title: "Upload build",
		},
		{
			path: process.env.REACT_APP_GAME_URL,
			title: "Play",
			out: true,
			color: "rgb(0,113,249)",
			colorFont: "white",
		},
	]

	const onClickLogout = () => {
		removePasswordFromSS()
		window.location.reload()
	}

	const onClickMenuItem = (item: (typeof menuItems)[0]) => {
		if (item.out) {
			window.open(item.path)
			return
		}
		navigate(item.path)
	}

	return (
		<MainLayoutWrapper>
			<MainLayoutHeader>
				<Title>Qubix Admin Panel | {process.env.REACT_APP_TITLE}</Title>
				<div></div>
				<AdminInfo>{adminInfo?.email || ""}</AdminInfo>
			</MainLayoutHeader>
			<MainLayoutMenu>
				<MenuContent>
					{menuItems.map(item => (
						<MenuItem onClick={() => onClickMenuItem(item)} color={item.color} colorFont={item.colorFont}>
							{item.title}
						</MenuItem>
					))}
					<Logout onClick={onClickLogout} color={"white"} colorFont={"black"}>
						Logout
					</Logout>
				</MenuContent>
			</MainLayoutMenu>
			<MainLayoutContent>{children}</MainLayoutContent>
		</MainLayoutWrapper>
	)
}

export default MainLayout
