export namespace ShowInSelect {
	export const path = "/game-build-storage/show_in_select"
	export class Request {
		mode: boolean
		buildVersion: string
	}
	export class ResponseSuccess {
		success: true
	}
	export class ResponseError {
		statusCode: number
		message: string
	}
}
