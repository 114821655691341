import { styled } from "styled-components"

export const UsersPageWrapper = styled.div`
	.ant-table {
		/* max-height: 70vh !important;
		overflow-y: auto !important; */
	}
`

export const Field = styled.div`
	display: grid;

	justify-content: left;
	align-items: center;

	grid-auto-flow: column;
	gap: 20px;

	div {
		text-align: left;
		margin-bottom: 5px;
	}
	input,
	textarea {
		border: 1px solid grey;
		border-radius: 5px;
		line-height: 20px;
		font-size: 16px;
		padding: 5px;

		font-family: Arial, Helvetica, sans-serif;
	}
`

export const ControlBlock = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
	align-items: center;

	padding: 20px;
	margin-bottom: 20px;

	border: 1px rgba(0, 0, 0, 0.1) solid;
	border-radius: 10px;
`
export const ControlItem = styled.div`
	width: max-content;

	display: grid;
	grid-auto-flow: column;
	align-items: center;
`
export const Title = styled.div``

export const UserFakeAvatar = styled.div`
	width: 40px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 50%;

	margin: 0 auto;

	display: grid;
	place-items: center;

	font-size: 8px;
`

export const UserAvatar = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 50%;
`
