import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { message, notification } from "antd"
import axios, { AxiosError, AxiosResponse } from "axios"
import { UploadBuild } from "src/interfaces/requests/uploadBuild"
import fast_debug from "src/utils/fast_debug"
import { getPasswordFromSS } from "./app.slice"

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	// headers: { Accept: "application/json", "Content-Type": "application/json" },
	withCredentials: true,
})

export const uploadBuildThunk = createAsyncThunk("uploadBuildThunk", async (formData: FormData, { getState, rejectWithValue, dispatch }) => {
	try {
		const res = await axiosInstance
			.post<{}, AxiosResponse<{}>, FormData>("/game-build-storage/upload_build", formData, {
				headers: { Authorization: `Bearer ${getPasswordFromSS()}`, "Content-Type": "multipart/form-data" },
				onUploadProgress: progressEvent => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					console.log(`Progress upload: ${percentCompleted} %`)
					dispatch(UploadSlice.actions.setUploadingProgress(percentCompleted))
				},
			})
			.then(res => res.data)
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

interface IState {
	progressUpload: number
	version: string
	description: string
	isUploading: boolean
}

const initialState: IState = {
	progressUpload: null,
	version: null,
	description: null,
	isUploading: false,
}

export const UploadSlice = createSlice({
	name: "UploadSlice",
	initialState,
	reducers: {
		setVersion(state, action: PayloadAction<string>) {
			state.version = action.payload
		},
		setDescription(state, action: PayloadAction<string>) {
			state.description = action.payload
		},
		setUploadingProgress(state, action: PayloadAction<number>) {
			if (!state.isUploading) {
				fast_debug("Нельзя установить прогресс загрузки есть она не запущена")
			} else {
				state.progressUpload = action.payload
			}
		},
	},
	extraReducers: {
		[uploadBuildThunk.pending.type]: state => {
			state.isUploading = true
			state.progressUpload = 0
		},
		[uploadBuildThunk.rejected.type]: (state, action: PayloadAction<AxiosError<UploadBuild.ResponseError>>) => {
			state.isUploading = false
			state.progressUpload = null
			console.log(action?.payload?.response)
			if (action?.payload?.response?.data?.message) {
				notification.error({ message: action?.payload?.response?.data?.message, duration: 3 })
			}
		},
		[uploadBuildThunk.fulfilled.type]: (state, action: PayloadAction<any>) => {
			state.isUploading = false
			state.progressUpload = null
			message.success("Success change")
			window.location.pathname = "/versions"
		},
	},
})

export default UploadSlice
