import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useAppDispatch } from "../../../src/state/hooks/redux"
import { getPasswordFromSS, loginThunk } from "../../../src/state/reducers/app.slice"

const WithAuthHOC = ({ children }: { children: React.ReactNode }) => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(loginThunk())
	}, [])

	const isAuthenticated = getPasswordFromSS()
	if (isAuthenticated) {
		return <>{children}</>
	} else {
		return <Navigate to="/auth" />
	}
}

export default WithAuthHOC
