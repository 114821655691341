export namespace DeleteVersion {
	export const path = "/game-build-storage/delete_build"
	export class Request {
		buildVersion: string
	}
	export class Response {
		success: boolean
	}
	export class ResponseError {
		statusCode: number
		message: string
	}
}
