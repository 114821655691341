import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Field, UploadPageWrapper, UploadZone } from "./styles"
import { useAppDispatch, useAppSelector } from "src/state/hooks/redux"
import UploadSlice, { uploadBuildThunk } from "src/state/reducers/uploadBuild.slice"
import { Button, Progress, message, notification } from "antd"
import fast_debug from "src/utils/fast_debug"

export const UploadPage = () => {
	const dispatch = useAppDispatch()
	const { description, progressUpload, version, isUploading } = useAppSelector(state => state.uploadBuildReducer)
	const [filesToUpload, setFilesToUpload] = useState<any>() // Хранит выбранные файлы

	const onDrop = acceptedFiles => {
		// Добавить выбранные файлы в состояние
		setFilesToUpload(acceptedFiles[0])
	}

	const onDropRejected = (fileRejections, event) => {
		try {
			console.log({ fileRejections, event })
			if (fileRejections?.length) {
				fileRejections?.forEach(file => {
					file.errors?.forEach(error => {
						message.error(`File ${file?.file?.name} \n ${error?.message}`)
					})
				})
			}
		} catch (error) {
			message.error(`Ошибка при загрузке файла`)
		}
	}

	const handleUpload = () => {
		if (isUploading) {
			message.warning("Загрузка уже идёт!")
			return
		}
		const formData = new FormData()
		formData.append("description", description)
		formData.append("version", version)
		formData.append("files", filesToUpload)

		dispatch(uploadBuildThunk(formData))
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, onDropRejected, maxFiles: 1, multiple: false, maxSize: 209715200, minSize: 10485760 })

	return (
		<UploadPageWrapper>
			<Field>
				<div>Version</div>
				<input type="text" value={version || ""} onChange={e => dispatch(UploadSlice.actions.setVersion(e?.target?.value))} />
			</Field>
			<Field>
				<div>Description</div>
				<textarea value={description || ""} onChange={e => dispatch(UploadSlice.actions.setDescription(e?.currentTarget?.value))} />
			</Field>
			<Field>
				<div>Archive</div>
				<UploadZone {...getRootProps()}>
					<input {...getInputProps()} />
					<p>Перетащите сюда .zip архив или нажмите для выбора из проводника</p>
				</UploadZone>
			</Field>
			{filesToUpload && (
				<div>
					<p>Выбранный файл:</p>
					<ul>
						<li key={filesToUpload.name}>{filesToUpload.name}</li>
					</ul>
				</div>
			)}
			{isUploading && <Progress percent={progressUpload} />}
			{isUploading && progressUpload === 100 && <p>Загрузка файлов в AWS S3 (~3-5мин)</p>}
			<Button type="primary" loading={isUploading} onClick={handleUpload}>
				Upload
			</Button>
		</UploadPageWrapper>
	)
}
