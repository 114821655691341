import { styled } from "styled-components"

export const UploadPageWrapper = styled.div``

export const UploadZone = styled.div`
	display: grid;
	place-items: center;
	border: dashed 1px black;
	/* height: 300px; */
	cursor: pointer;
`

export const Field = styled.div`
	padding: 0 0 10px;
	div {
		margin-bottom: 5px;
	}
	input,
	textarea {
		border: 1px solid grey;
		border-radius: 5px;
		line-height: 20px;
		font-size: 16px;
		padding: 5px;

		font-family: Arial, Helvetica, sans-serif;
	}
	textarea {
		min-height: 100px;
		min-width: 300px;
	}
`
