export namespace ChangeUserGameBuild {
	export const path = "/game-build-storage/change_user_build"
	export class Request {
		userId: number
		buildVersion: string
	}
	export class ResponseSuccess {
		success: true
	}
	export class ResponseError {
		statusCode: number
		message: string
	}
}
