import { BrowserRouter as Router } from "react-router-dom"
import AppRouter from "./routes/AppRouter"
import { notification } from "antd"
import { ThemeProvider } from "styled-components"

function App() {
	notification.config({ duration: 2 })

	const theme = {
		mainBackgroundColor: "white",
	}
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<AppRouter />
			</Router>
		</ThemeProvider>
	)
}

export default App
